import React from 'react'
import { useTranslation } from 'react-i18next'

import LayoutContent from '../components/layout/layoutContent'

const GonePage = props => {
    const { t } = useTranslation()

    return (
        <LayoutContent {...props} hasVerticalGutters>
            <h1>{t('Gone')}</h1>
            <p>{t('We’ve retired this page. It served the COG well.')}</p>
        </LayoutContent>
    )
}

export default GonePage
